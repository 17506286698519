// Fonts
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Roboto', sans-serif;

// Colors
$color-accent: #d4a259;
$gray-darkest: #1d1d1d;
$gray-dark: #333;
$gray: #666;
$gray-light: #aaa;
$gray-lighter: #eee;
$yellow: #fffac4;

$link-green: #88bd60;